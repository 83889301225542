import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const ImageList = loadable(() => import('/src/components/PageBuilder/layouts/standard/ImageList'))

const Ratings = ({ backgroundColour }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            ratings {
                                fieldGroupName
                                ratings {
                                    numberOfColumns
                                    addTitleArea
                                    title
                                    description
                                    imageList {
                                        image {
                                            id
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(width: 230)
                                                }
                                            }
                                            altText
                                            title
                                        }
                                        link {
                                            target
                                            title
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        let componentData = node.globalBlocks.ratings.ratings

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: 'centered',
            title: componentData.title,
            description: componentData.description
        }

        return (
            <ImageList
                key={index}
                backgroundColour={backgroundColour}
                numberOfColumns={componentData.numberOfColumns}
                titleArea={titleArea}
                imageList={componentData.imageList}
                className={'c-ratings'}
            />
        )
    })
}

Ratings.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired
}

Ratings.defaultProps = {
    backgroundColour: 'light'
}

export default Ratings

export const query = graphql`
    fragment RatingsForPageBuilder on WpPage_Acfpagebuilder_Layouts_Ratings {
        fieldGroupName
        backgroundColour
    }
`
